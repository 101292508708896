<template>
  <div>
    <super-header></super-header>
    <div v-if="entries">

      <h1 className="text-center">Entries</h1>

      <div className="grid-canvas">
        <table id="grid-canvas">

          <thead>
            <tr>
              <th>Title</th>
              <th>Image</th>
              <th>Actions</th>
            </tr>
          </thead>

          <tbody>

            <tr v-for="(entry) in entries" :key="entry.id" class='ui-widget-content slick-row'>

              <td><span v-html="entry.title" /></td>
              
              <td>
                <div style="width: '130px;">
                  <a :href="S3_URL + '/' + entry.id + '/featured/' + entry.image" target="_blank">
                    <img class="card-img-top" :src="S3_URL + '/' + entry.id + '/featured/' + entry.image" />
                  </a>
                  <div class="action-btn" v-on:click="downloadFile(entry)">Download</div>
                </div>
              </td>


              <td>

                <span v-on:click="removeItem(entry.id)" class="action-btn">Delete</span>

                <router-link :to="'/super/listings/' + entry.id" class="action-btn">Edit</router-link>

              </td>

            </tr>

          </tbody>

        </table>

      </div>

    </div>

  </div>
</template>
<script>
// import EntryForm from 'components/contests/EntryForm.vue';
import {ENV_URL, S3_URL, MAIN_URL, API_URL, STRIPE_PUBLISHABLE} from '../../environment.js';
import SuperHeader from 'components/super/SuperHeader.vue';
import axios from 'axios';

export default {
  name: 'directory-view',

  components: {
    SuperHeader,
  },
  data () {
    console.log('current page', this.$route)
    return {
      entries: null,
      S3_URL: S3_URL,

      // page: this.$route.query['page'] ? Number(this.$route.query['page']) : 1,
    }

  },
  created() {
    let entries = this.entries;
    // If entriesData is empty, or if we're switching the contest (e.g.,
    // the slug of the current contest does not match what's in state)
    if (!entries) {
      // this.$store.dispatch('TOGGLE_LOADING', {loading: this.$store.state.loading})
      // this.$bar.start();


      this.$store.dispatch('LOADING', true);


      const uri = ENV_URL + '/super/listings';
      
      // this.props.getContest(uri);

      let authHeaders = {};

      if (typeof window !== "undefined") {
        var laravelAuthToken = localStorage.getItem('laravelAuthToken');

        authHeaders = {
          headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
        };
      }

      console.log('authHeaders in super', authHeaders);

      // let query_params = window.location.search;
      // Example query: /items/get_super_items?active=1&contest_id=14&search=Hold%20your%20tongue&column_name=item_description
      axios.get(uri, authHeaders)
      .then((response) => {

        this.$store.dispatch('LOADING', false);

        console.log('editable response: ', response);
        if (response.data.logged_in == false) {
          alert(response.data.message);
        }
        this.entries = response.data.listings;

      })
      .catch(function (error) {
        console.log(error);
      })
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted() {
    },
    // Remove the entry from the database and the view
    removeItem(id) {
      const user_confirm = window.confirm('Are you sure you want to delete this item?');
      if (user_confirm == false) {
        return;
      }
      var laravelAuthToken = localStorage.getItem('laravelAuthToken');

      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };
      // remove the item from the database
      let uri = ENV_URL + '/items/' + id;
      axios.delete(uri, authHeaders).then((response) => {
        console.log('del res: ', response);
      });
      this.entries = this.entries.filter(function(entry) {
          return entry.id !== id
        })
      // // remove the item from state (removes the item from the view)
      // this.setState({
      //   items: this.state.items.filter(function(item) {
      //     return item.id !== id
      //   })
      // });
    },
  },

  watch: {
    '$route' (to, from) {


    }
  },
}
</script>

<style lang="scss">

</style>
